// LARGE DESKTOP
// >= 1600
@media (min-width: 1600px) 

	.container
		width: 1500px
	
	.map-section
		background: url(../img/map.jpg) 0 0 no-repeat
		background-size: cover

	.map-bg
		display: none

	.map-slide
		right: -2000px

	.map, .map-icons
		left: -50px





// DESKTOP
// 1024-1200
@media (max-width: 1200px) 
	
	.container
		width: 94%

	.nav
		left: 10px

	.map, .map-icons
		left: -200px

	.first-screen, .second-screen, .competition
		background-attachment: scroll


// TABLET
// 768-1023
@media (max-width: 1023px)

	.first-screen
		padding: 20px 0

		h1
			margin: 20px 0
			font-size: 40px

	.map, .map-icons
		top: -60px

	.map-bg
		top: 60px


	.map
		height: 130%
	
	.map-slide
		top: 0px

	.map-icon

		&.map-icon-1
			top: 124px
			left: 331px

		&.map-icon-2
			top: 107px
			left: 492px

		&.map-icon-3
			top: 207px
			left: 235px

		&.map-icon-4
			top: 200px
			left: 400px

		&.map-icon-5
			top: 198px
			left: 603px

		&.map-icon-6
			top: 120px
			left: 697px

		&.map-icon-7
			top: 335px
			left: 286px

	.section.section-2, .section
		min-height: 450px
		padding: 80px 0

	.map-section.section-2
		min-height: 500px
		padding-top: 198px

	.first-screen
		padding-top: 30px

	.feedback.section-2
		min-height: 1px
		padding-bottom: 0

	.feedback .container
		min-height: 350px

	.iphone
		right: 0
		width: 270px

	.competition-img
		width: 300px
		margin-top: 0



// MOBILE
// 0-767
@media (max-width: 767px)

	.nav
		padding-top: 9px
		top: 0
		left: 0
		width: 100%
		height: 28px
		background: rgba(0, 0, 0, 0.2)
		text-align: center

		li
			display: inline-block
			vertical-align: top
			margin: 0 4px

	.first-screen 
		padding-top: 50px

		h1
			margin: 30px 0
			font-size: 15px

	.logo
		width: 71%

	.btn
		padding: 14px 25px 15px 31px
		font-size: 12px

		&:after
			width: 13px
			height: 8px
			margin: 2px 0 0 10px
			background-size: cover

	.middle-line
		width: 1px

		.circle
			top: -8px
			left: -3px
			width: 8px
			height: 8px
			border-width: 1px

	.first-screen  .middle-line .circle
		top: -8px

	.section 

		h2
			font-size: 22px

			small
				margin-top: 3px
				font-size: 15px

		p
			font-size: 15px

		.slider
			min-height: 91px

		.slider-heading
			margin: 20px 0

			span
				padding: 12px 16px

	.section.section-2, .section
		min-height: 1px
		padding: 120px 0

	.vertical-align
		display: block
		height: auto

	.middle-cell
		display: block

	.map-section
		height: 351px

	.map, .map-icons
		top: 0
		left: 0

	.map
		height: 100%

	.map-bg
		width: 100%
		height: auto

	.map-icon

		&.map-icon-1
			width: 20px
			top: 62px
			left: 68px

		&.map-icon-2
			width: 37px
			top: 272px
			left: 173px

		&.map-icon-3
			width: 35px
			top: 265px
			left: 50px

		&.map-icon-4
			width: 35px
			top: 52px
			left: 172px

		&.map-icon-5
			width: 20px
			height: 20px
			top: 80px
			left: 244px

		&.map-icon-6
			width: 36px
			top: 249px
			left: 254px

		&.map-icon-7
			width: 40px
			top: 122px
			left: 40px

	.map-section.section.section-2
		padding-top: 108px
		height: 350px
	
	.map
		width: 1000px

	.map-bg
		top: 0

	.circle-big
		left: -33px
		width: 66px
		height: 72px
	
	.map-section .circle-big
		top: 100%
		background-size: cover

	.iphone-container
		float: right
		position: relative
		right: auto
		bottom: auto
		margin-top: 20px		
	
	.iphone
		float: right
		position: relative
		right: auto
		bottom: auto
		width: 200px
		height: 220px

	.feedback.section-2
		padding: 60px 0 0 0

	.competition.section-2
		padding: 37px 0
		background-position: 74% 0
		text-align: center

	.competition-img
		float: none
		width: 130px
		margin: 0 0 30px 0

	.contact.section
		padding: 65px 0 0 0

	.contact-form
		width: 81%
		margin-top: 28px

		input[type="text"],
		textarea,
		input[type="submit"]
			height: 30px
			margin-bottom: 10px
			padding: 5px 8px 4px 8px
			border-radius: 4px
			font-size: 12px

		textarea
			height: 93px

	.footer
		padding: 30px 0

		p
			margin-top: 13px
			font-size: 8px

	.logo-footer
		display: inline-block
		width: 38%

	.section p.error
		margin-top: -7px
		padding: 7px 10px
		font-size: 12px

	.success
		width: 100%
		margin: 20px auto 0px auto
		font-size: 18px

	.feedback 

		.middle-line .circle-big
			top: 99%
			background-size: cover

		.middle-line-2
			top: 68.5%
			height: 32%

	.contact .middle-line .circle
		bottom: -8px
		top: auto

	.feedback .container
		min-height: 1px





